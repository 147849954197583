import { Col, Row } from 'antd'
import { ReactNode, useState } from 'react'
import './section-layout.scss'

export interface SectionLayoutProps {
  /**
   * title of layout
   */
  title?: string

  /**
   * child component
   */
  children?: ReactNode
  /**
   * all function of button in header
   */
  action?: ReactNode

  /**
   * alert for layout
   */
  alert?: ReactNode

  /**
   * all function of button in header
   */
  leftAction?: ReactNode
  pdBot?: string
}

/**
 * The @SectionLayout là bảng dùng để layout cho các thành phần trong modal reference
 * @author [chiendd]
 * @version 0.1
 */
const SectionLayout = (props: SectionLayoutProps) => {
  const {
    title = '',
    children = <></>,
    action = <></>,
    alert = <></>,
    leftAction = <></>,
    pdBot,
  } = props
  const [isExpand, setIsExpand] = useState(true)
  const onToggle = () => {
    setIsExpand(!isExpand)
  }
  return (
    <div className={`section-layout ${!pdBot ? 'pb-2' : pdBot} mb-4`}>
      <Row
        className="head bd-bot-page-title pb-1.5 pr-2"
        align={'middle'}
        justify={'space-between'}
      >
        <Col>
          <Row align="middle">
            <Col>
              <span className="page-title-heading-section-modal">{title}</span>
            </Col>
            <Col>
              <span>{alert}</span>
            </Col>
            <Col>{leftAction}</Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={10} align="middle">
            <Col>{action}</Col>
            <Col>
              <i
                className={isExpand ? 'icon-arrow-down ' : 'icon-arrow-up'}
                onClick={onToggle}
              ></i>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className={`${isExpand ? 'block' : 'hidden'} pt-5`}>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default SectionLayout
