import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import Define from '../../../constants/define'
import { FormCreationType } from '../../../enum'
import { NameAndValue } from '../../../features/stock/stockscreen/component/Wrapper'
import { removeTones } from '../../../utils'
import './CustomSearch.scss'
import { useTranslation } from 'react-i18next'

export interface OptionItemCustomSearch {
  //id
  value?: string
  //code_nom
  label?: string
  //code (for search)
  code?: string
}

interface CustomSearchProps {
  /**
   * max digit of input search
   */
  maxTextLength?: number
  /**
   *
   * @param data function call api
   * @returns a promise
   */
  functionSearch: (payload: {
    data: string
    client_code_nom: string
  }) => Promise<any>
  /**
   *
   * @param newValue is an object includes name and value
   * @returns void
   */
  onChange?: (newValue: NameAndValue, code: string) => void
  /**
   * current value
   */
  value?: string
  /**
   * classname of the input
   */
  className?: string
  /**
   * show error
   */
  forceShowError?: string
  /**
   * disabled search
   */
  disabled?: boolean
  /**
   * type of form creation
   */
  type?: FormCreationType
  id?: string
  nextId?: string
  previousId?: string
}

const DEFAULT_MAX_LENGTH = 43

/**
 * The @CustomSearch is a select to search for items. When users enter characters it will show maximum 6 items.
 * @author [anhnq]
 * @version 0.1
 */
const CustomSearch = (props: CustomSearchProps) => {
  const {
    functionSearch,
    onChange = () => {},
    className = '',
    forceShowError = '',
    disabled = false,
    type,
    id,
    nextId,
    previousId,
  } = props

  const [valueSearch, setValueSearch] = useState<string>('')
  const { t } = useTranslation()
  const defaultOption = {
    value: 'Enlèvement client',
    label: 'Enlèvement client',
  }

  const [options, setOptions] = useState<OptionItemCustomSearch[]>(
    type === FormCreationType.LIVRAISON ? [defaultOption] : []
  )

  const onSelect = (newValue: string, option: OptionItemCustomSearch) => {
    const code = options.find((el) => el.label === option.label)?.code || ''
    onChange({ name: option.value, value: newValue }, code)
  }

  const debounceOnSearchValue = useDebounce(valueSearch, 800)

  const handleSearch = (newValue: string) => {
    const MAX_LENGTH = props?.maxTextLength || DEFAULT_MAX_LENGTH
    if (!newValue) {
    }
    if (newValue.length >= MAX_LENGTH) {
      newValue = newValue.substring(0, MAX_LENGTH)
      setValueSearch(newValue)
    } else {
      setValueSearch(newValue)
    }
  }

  const handleChangeValue = (newValue: string) => {
    setValueSearch(removeTones(newValue))
  }

  useEffect(() => {
    if (!debounceOnSearchValue) return
    let payload = {
      data: debounceOnSearchValue,
      client_code_nom:
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
    }
    functionSearch(payload).then((res) => {
      let response = res?.data?.entry

      if (response) {
        if (type === FormCreationType.LIVRAISON) {
          setOptions([
            defaultOption,
            ...response.map((el: any) => ({
              value: el.id,
              label: `${el.code}_${el.nom}`,
              code: el.code,
            })),
          ])
        } else {
          setOptions(
            response.map((el: any) => ({
              value: el.id,
              label: `${el.code}_${el.nom}`,
              code: el.code,
            }))
          )
        }
      } else {
        if (type === FormCreationType.LIVRAISON) setOptions([defaultOption])
        else setOptions([])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceOnSearchValue])

  return (
    <div className="relative">
      <Select
        id={id}
        tabIndex={0}
        aria-roledescription={nextId}
        aria-keyshortcuts={previousId}
        showSearch
        disabled={disabled}
        className={`${className} custom-search`}
        searchValue={valueSearch}
        showArrow={false}
        filterOption={false}
        notFoundContent={
          <div className="text-center">{t('notFoundResult')}</div>
        }
        options={(options || []).map((d: any) => ({
          value: d.value,
          label: d.label,
        }))}
        onSearch={handleSearch}
        onChange={handleChangeValue}
        onSelect={onSelect}
        value={props.value}
        status={forceShowError ? 'error' : ''}
        defaultValue={
          type === FormCreationType.LIVRAISON ? 'Enlèvement client' : undefined
        }
      />
      <p
        className="absolute text-red top-8 font-medium"
        style={{ fontSize: 9 }}
      >
        {forceShowError}
      </p>
    </div>
  )
}

export default CustomSearch
