import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { cloneDeep, isEmpty } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import CustomSearch from '../../../../components/Common/CustomSearch/CustomSearch'
import { InputType } from '../../../../enum/InputType'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  ITableProps,
  LineParam,
  TitleParam,
} from './model'
import {
  CCell,
  CInput,
  CTable,
  CTr,
  InvalidProperty,
  NameAndValue,
} from './Wrapper'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'

interface CellPosition {
  lineNumber: number
  name: string
}

// interface OptionTypes {
//     index: number,
//     options: OptionType[]
// }
/**
 * The @ITable là bảng dùng để thực hiện các action phức tạp
 * @author [chiendd]
 * @version 0.1
 */

function getUniqueParentIds(data: DataTableParam[][]) {
  let uniqueIDs: string[] = []
  data.forEach((list) => {
    const parentId = list.find((i) => i.name === 'parent_id')?.value
    if (parentId && !uniqueIDs.includes(parentId)) {
      uniqueIDs.push(parentId)
    }
  })
  return uniqueIDs
}

const ITable = (props: ITableProps) => {
  const {
    header,
    data,
    hideCols = [],
    formatCols = [],
    formatRows = [[]],
    forceReset = [],
    blockTable = false,
    lineClass,
    name = '',
    previousId,
  } = props
  const [mergeData, setMergeData] = useState<LineParam[][]>([])
  const [scrollToCell, setSCrollToCell] = useState<CellPosition>()
  const [closeLines, setCloseLines] = useState<string[]>(
    getUniqueParentIds(data)
  )
  const [listUuid, setListUuid] = useState<string[]>([])

  useEffect(() => {
    setListUuid(mergeData.map((item) => uuidv4()))
  }, [mergeData])

  // const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([]);

  useEffect(() => {
    const newMergeData: LineParam[][] = []
    let dataClone = cloneDeep(data)
    let headerClone = cloneDeep(header)
    dataClone.forEach((currentLine, lineIdx) => {
      let merge: LineParam[] = []
      headerClone.forEach((currentHeader, idx) => {
        //const indexCurrentCell = currentLine.findIndex(item => item.name === currentHeader.name);
        const currentCell = currentLine.find(
          (cell) => cell.name === currentHeader.name
        )
        const currentFormatCol = formatCols?.find(
          (f) => f.name === currentHeader.name
        )
        const currentFormatCell = formatRows[lineIdx]?.find(
          (f) => f.name === currentHeader.name
        )
        let currentForceReset: boolean
        if (forceReset[lineIdx]) {
          currentForceReset =
            forceReset[lineIdx].find((item) => item.name === currentHeader.name)
              ?.value || false
        } else currentForceReset = false

        const result = {
          ...cloneDeep(currentHeader),
          ...cloneDeep(currentCell),
          forceReset: currentForceReset,
        }
        if (result.options) result.cInputProps.options = result.options
        if (currentFormatCol || currentFormatCell) {
          result.cInputProps.format =
            currentFormatCol?.format || currentFormatCell?.format
        }
        if (currentCell?.maxLength)
          result.cInputProps.maxLength = currentCell.maxLength
        if (currentCell?.type) result.cInputProps.type = [...currentCell.type]
        if (currentCell?.validates)
          result.cInputProps.validates = currentCell.validates
        if (currentCell?.required) result.cInputProps.required = true
        if (currentCell?.requiredVariant)
          result.cInputProps.requiredVariant = true
        if (currentCell?.requiredMessage)
          result.cInputProps.requiredMessage = currentCell.requiredMessage
        if (currentCell?.confirmMode)
          result.cInputProps.confirmMode = currentCell.confirmMode
        if (currentCell?.valueAfterNotConfirm)
          result.cInputProps.valueAfterNotConfirm =
            currentCell.valueAfterNotConfirm
        if (currentCell?.messageConfirm)
          result.cInputProps.messageConfirm = currentCell.messageConfirm
        if (currentCell?.bold) result.cInputProps.bold = currentCell.bold
        if (currentCell?.refreshAction)
          result.cInputProps.refreshAction = currentCell.refreshAction
        else result.cInputProps.refreshAction = false
        if (currentCell?.allowRefresh)
          result.cInputProps.allowRefresh = currentCell.allowRefresh
        else result.cInputProps.allowRefresh = false

        if (currentCell?.mustRefresh)
          result.cInputProps.mustRefresh = currentCell.mustRefresh
        else result.cInputProps.mustRefresh = false

        if (currentCell?.mustRemove)
          result.cInputProps.mustRemove = currentCell.mustRemove
        else result.cInputProps.mustRemove = false

        if (currentCell?.forceInvisible)
          result.cInputProps.forceInvisible = currentCell.forceInvisible
        else result.cInputProps.forceInvisible = false
        merge.push(result)
      })
      newMergeData.push(merge)
    })
    setMergeData(newMergeData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header, data, formatCols, formatRows])

  const onChange = (index: number, data: NameAndValue, nameChange: string) => {
    if (!props?.onChangeDataTable) return
    props?.onChangeDataTable(index, data)
  }
  const onCheckValid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    if (props?.onCheckInvalid) {
      props?.onCheckInvalid(index, invalidProperty)
    }
  }

  const onCheckFillRequired = (
    index: number,
    listNotFillRequired: string[]
  ) => {
    if (props?.onCheckFillRequired) {
      props?.onCheckFillRequired(index, listNotFillRequired)
    }
  }

  const onConfirm = (index: number, name: string, otherValue?: any) => {
    if (props?.onConfirm) {
      props?.onConfirm(index, name, otherValue)
    }
  }
  const onNotConfirm = (index: number, name: string, otherValue?: any) => {
    if (props?.onNotConfirm) {
      props?.onNotConfirm(index, name, otherValue)
    }
  }

  useEffect(() => {
    let forceError = props?.forceShowError || []
    for (let i = 0; i < forceError.length; i++) {
      if (!isEmpty(forceError[i])) {
        const newScrollToCell: CellPosition = {
          name: forceError[i][0]?.name,
          lineNumber: i,
        }
        setSCrollToCell(newScrollToCell)

        break
      }
    }
  }, [props?.forceShowError])

  function changeShowLine(i: string = '') {
    const closeLinesClone = [...closeLines]
    if (closeLines.includes(i)) {
      setCloseLines(closeLinesClone.filter((line) => line !== i))
    } else {
      setCloseLines([...closeLinesClone, i])
    }
  }

  const onRefresh = (nameAndValue: NameAndValue, id: string) => {
    if (props?.onRefresh) props.onRefresh(nameAndValue, id)
  }

  // console.log({'props.actions: ': props.actions && props.actions?.length > 0 ? props.actions[0]?.isFillAllRequired : ''})
  return (
    <CTable>
      <Header header={header} hideCols={hideCols} actions={props.actions} />
      {mergeData.map((item, idx) => {
        const parentId = data[idx]?.find((i) => i.name === 'parent_id')?.value
        let isParentLine = !parentId
        const isClose = parentId ? closeLines.includes(parentId) : false
        const id = data[idx]?.find((i) => i.name === 'id')?.value
        // eslint-disable-next-line array-callback-return
        const hasChild = data?.find((line) => {
          const parent = line?.find((i) => i.name === 'parent_id')?.value
          if (parent === id) {
            return true
          }
        })
        return (
          <Line
            scrollToCol={
              idx === scrollToCell?.lineNumber ? scrollToCell.name : ''
            }
            resetFlag={props.resetFlags[idx]}
            hideCols={hideCols}
            header={header}
            action={props?.actions ? props?.actions[idx] : undefined}
            isLastLine={idx === mergeData.length}
            key={idx}
            index={idx}
            line={item}
            isDisableLine={blockTable || props?.disabledLines.includes(idx)}
            onChange={onChange}
            onCheckValid={onCheckValid}
            onCheckFillRequired={onCheckFillRequired}
            onConfirm={onConfirm}
            onNotConfirm={onNotConfirm}
            forceShowError={
              props?.forceShowError ? props?.forceShowError[idx] || [] : []
            }
            forceShowWarning={
              props?.forceShowWarning ? props?.forceShowWarning[idx] || [] : []
            }
            actionNode={props?.actionNode}
            actionStyle={props?.actionStyle}
            inTwoGradeTable={Boolean(props.inTwoGradeTable)}
            isCollapse={id ? closeLines.includes(id) : false}
            isParentLine={isParentLine}
            hasChild={!!hasChild}
            setShowLine={() => changeShowLine(id)}
            isClose={isClose}
            lineClass={lineClass}
            onRefresh={(nameAndValue) => onRefresh(nameAndValue, id || '')}
            tableName={name}
            previousId={previousId}
          />
        )
      })}
    </CTable>
  )
}

interface HeaderProps {
  header: TitleParam[]
  hideCols: string[]
  actions?: ActionParam[]
}

export const Header = (props: HeaderProps) => {
  return (
    <CTr isHeader={true} className="sticky top-0 z-10 ">
      {props.header.map((item, idx) => {
        if (props.hideCols.includes(item?.name || ''))
          return <div key={idx} className="hidden"></div>
        if (item?.required) {
          return (
            <CCell
              key={idx}
              className={` whitespace-nowrap bd-bot-header-table bd-right-header-table py-3 text-center ${item.cInputProps.className}`}
            >
              <span className="text-header-table txt-required">
                {item.label}
              </span>
            </CCell>
          )
        }
        return (
          <CCell
            key={idx}
            className={`whitespace-nowrap bd-bot-header-table bd-right-header-table py-3 text-center ${item.cInputProps.className}`}
          >
            <span className="text-header-table">{item.label}</span>
          </CCell>
        )
      })}

      {props?.actions && props.actions.length > 0 && (
        <CCell
          key={-1}
          className="whitespace-nowrap bd-bot-header-table text-center"
        >
          <span className="text-header-table">Actions</span>
        </CCell>
      )}
    </CTr>
  )
}

interface LineProps {
  isLastLine?: boolean
  header: TitleParam[]
  line: LineParam[]
  index: number
  action?: ActionParam
  isDisableLine?: boolean
  forceShowError?: ForceShowError[]
  forceShowWarning?: ForceShowWarning[]
  hideCols?: string[]
  resetFlag?: boolean
  scrollToCol?: string
  confirms?: NameAndValue[]
  // forceReset: boolean[],
  actionNode?: (index: number, actionParam?: ActionParam) => ReactNode | null
  onChange?: (index: number, data: NameAndValue, nameChange: string) => void
  onCheckValid: (index: number, invalidProperty: InvalidProperty | null) => void
  onCheckFillRequired: (index: number, listNotFillRequired: string[]) => void
  functionSearch?: (data: any) => Promise<any>
  onConfirm?: (index: number, name: string) => void
  onNotConfirm?: (index: number, name: string) => void
  inTwoGradeTable: boolean
  isCollapse?: boolean
  isParentLine?: boolean
  hasChild: boolean
  setShowLine: () => void
  isClose: boolean
  actionStyle?: string
  lineClass?: (val: any) => void
  textStyleFollowValue?: (
    value: string,
    currentLine: DataTableParam[]
  ) => string
  refreshAction?: boolean
  onRefresh?: (nameAndValue: NameAndValue) => void
  tableName?: string
  previousId?: string
}

function getNextId(
  line: LineParam[],
  lineNumber: number,
  idx: number,
  tableName?: string
) {
  let nextId = ''
  for (let i = idx + 1; i < line.length; i++) {
    if (
      !line[i].cInputProps?.disabled &&
      !line[i]?.disabled &&
      line[i]?.name &&
      !line[i].cInputProps.type?.includes(InputType.IMG)
    ) {
      nextId += `${tableName}${line[i].name}-${lineNumber}, `
    }
  }
  return `${nextId}${tableName}icon-valid-${lineNumber}, ${tableName}icon-delete-${lineNumber}`
}

function getPreviousId(
  line: LineParam[],
  lineNumber: number,
  idx: number,
  tableName?: string
) {
  let previousId = ''
  for (let i = idx - 1; i >= 0; i--) {
    if (
      !line[i].cInputProps?.disabled &&
      !line[i]?.disabled &&
      line[i]?.name &&
      !line[i].cInputProps.type?.includes(InputType.IMG)
    ) {
      previousId += `${tableName}${line[i].name}-${lineNumber}, `
    }
  }
  return previousId
}

const Line = (props: LineProps) => {
  const {
    inTwoGradeTable,
    isCollapse,
    isParentLine,
    isClose,
    setShowLine,
    hasChild,
    index,
    tableName,
  } = props
  const [data, setData] = useState<LineParam[]>(props?.line)
  useEffect(() => {
    setData(cloneDeep(props?.line || []))
  }, [props?.line])

  const onChange = (nameAndValue: NameAndValue) => {
    if (props?.onChange)
      props?.onChange(props?.index, nameAndValue, nameAndValue?.name || '')
  }

  const onCheckValid = (data: InvalidProperty | null): void => {
    if (props?.onCheckValid) props?.onCheckValid(props?.index, data)
  }
  const onConfirm = (name: string) => {
    if (props?.onConfirm) props?.onConfirm(props?.index, name)
  }

  const onNotConfirm = (name: string) => {
    if (props?.onNotConfirm) props?.onNotConfirm(props?.index, name)
  }

  useEffect(() => {
    const listNotFillRequired: string[] = []
    data.forEach((item) => {
      if (item?.required && !item?.value) {
        listNotFillRequired.push(item?.name || '')
      }
    })
    if (props?.onCheckFillRequired) {
      props?.onCheckFillRequired(props?.index, listNotFillRequired)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {}, [props?.forceShowError])

  const classStyle = props?.lineClass ? props.lineClass(props.line) : ''

  const onRefresh = (nameAndValue: NameAndValue) => {
    if (props?.onRefresh) props.onRefresh(nameAndValue)
  }

  const genNode = (index: number, action?: ActionParam) => {
    if (!props?.actionNode) return <span></span>
    return props.actionNode(index, action)
  }

  // console.log("action i line: ", props.action)

  return (
    <CTr
      className={
        inTwoGradeTable && isClose ? 'hidden' : `line-in-table ${classStyle}`
      }
    >
      {inTwoGradeTable && (
        <CCell className="mx-8 flex items-center justify-center">
          {isParentLine && hasChild && (
            <div onClick={setShowLine} style={{ cursor: 'pointer' }}>
              {isCollapse ? <DownOutlined /> : <UpOutlined />}
            </div>
          )}
        </CCell>
      )}
      {(props.line || []).map((item, idx) => {
        const disabled = item?.disabled || false
        const require = item?.required || false
        const forceReset = item.forceReset
        item.cInputProps.disabled = props?.isDisableLine || false
        item.cInputProps.required = require
        item.cInputProps.forceReset = forceReset
        const cellClass = item.cInputProps.cellClass
        const id = `${tableName}${item.name}-${index}`
        const nextId = getNextId(props.line, index, idx, tableName)
        const previousId =
          getPreviousId(props.line, index, idx, tableName) || props.previousId

        if (item.name !== 'icone') item.cInputProps.className = 'css-for-input'

        if (item.cInputProps.invisible) {
          return null
        }
        if (item.cInputProps.forceInvisible)
          return (
            <CCell key={idx} className={cellClass}>
              <div className="text-center">-</div>
            </CCell>
          )

        if (item.cInputProps.invisibleIfChild && !isParentLine) {
          return (
            <CCell key={idx} className={cellClass}>
              <div className="text-center">-</div>
            </CCell>
          )
        }

        if (props?.hideCols?.includes(item.name || ''))
          return <div key={idx} className="hidden"></div>

        if (item.cInputProps.element) {
          const Element = item.cInputProps.element
          return (
            // @ts-ignore
            <Element
              id={id}
              nextId={nextId}
              previousId={previousId}
              key={idx}
              value={item.value}
              line={props.line}
              disabled={disabled || item.cInputProps.disabled}
              onChange={onChange}
              warningMessage={
                props?.forceShowWarning?.find((e) => e.name === item.name)
                  ?.message
              }
            />
          )
        }

        if (item.cInputProps.type?.includes(InputType.SEARCH)) {
          const defaultPromise = (value: any) => {
            return Promise.resolve('')
          }

          return (
            <CCell
              key={idx}
              className={clsx(
                'relative top-0 left-0 bd-right-cell-tables',
                cellClass
              )}
            >
              <span>
                <CustomSearch
                  id={id}
                  nextId={nextId}
                  previousId={previousId}
                  onChange={(value) =>
                    onChange({ name: item.name, value: value.value })
                  }
                  functionSearch={
                    item.cInputProps.functionSearch || defaultPromise
                  }
                  value={item.value}
                  className={item.cInputProps.className}
                  forceShowError={
                    props?.forceShowError?.find((e) => e.name === item.name)
                      ?.message
                  }
                  disabled={item.cInputProps.disabled}
                />
              </span>
            </CCell>
          )
        }
        if (item?.unit)
          return (
            <CCell key={idx} className=" bd-right-cell-table">
              <div className="flex items-center">
                <div>
                  <BoxInput>
                    <CInput
                      id={id}
                      nextId={nextId}
                      previousId={previousId}
                      className="custom-input input-disabled"
                      resetFlag={props?.resetFlag}
                      onCheckValid={onCheckValid}
                      onChange={onChange}
                      name={item.name}
                      required={item?.required}
                      {...item.cInputProps}
                      disabled={item.cInputProps.disabled || Boolean(disabled)}
                      value={item.value}
                      defaultValue={item?.value}
                      errorMessageForce={
                        props?.forceShowError?.find((e) => e.name === item.name)
                          ?.message
                      }
                      onConfirm={onConfirm}
                      onNotConfirm={onNotConfirm}
                      line={props.line}
                    />
                  </BoxInput>
                </div>
                <div className="gap-hor-4"></div>
                <div className="flex items-center">
                  <span className="unit-table">{item.unit}</span>
                </div>
              </div>
            </CCell>
          )

        //const errorMessage = props?.forceShowError?.find(e => e.name === item.name)?.message;
        const warningMessage = props?.forceShowWarning?.find(
          (e) => e.name === item.name
        )?.message
        const errorMessage = props?.forceShowError?.find(
          (e) => e.name === item.name
        )?.message
        //const requiredVariant = item.cInputProps.requiredVariant
        return (
          <CCell key={idx} className={clsx(' bd-right-cell-table', cellClass)}>
            <span>
              <BoxInput
                requiredVariant={item.cInputProps.requiredVariant}
                warningMessage={!errorMessage ? warningMessage : ''}
              >
                <CInput
                  id={id}
                  nextId={nextId}
                  previousId={previousId}
                  className="custom-input"
                  resetFlag={props?.resetFlag}
                  onCheckValid={onCheckValid}
                  onChange={onChange}
                  name={item.name}
                  required={item?.required}
                  {...item.cInputProps}
                  disabled={item.cInputProps.disabled || Boolean(disabled)}
                  value={item.value}
                  defaultValue={item?.value}
                  errorMessageForce={
                    props?.forceShowError?.find((e) => e.name === item.name)
                      ?.message
                  }
                  onSearch={item.cInputProps.onSearch}
                  options={item.cInputProps.options}
                  warningMessage={warningMessage}
                  onConfirm={onConfirm}
                  onNotConfirm={onNotConfirm}
                  bold={item.cInputProps.bold}
                  onRefresh={(nameAndValue) => onRefresh(nameAndValue)}
                  line={props.line}
                />
              </BoxInput>
            </span>
          </CCell>
        )
      })}
      {props?.actionNode && (
        <CCell className={props.actionStyle}>
          {genNode(props?.index, props?.action)}
        </CCell>
      )}
    </CTr>
  )
}

export default ITable

interface BoxInputProps {
  children: ReactNode
  warningMessage?: string
  errorMessage?: string
  requiredVariant?: boolean
}

const BoxInput = (props: BoxInputProps) => {
  return (
    <div className="box-input">
      {props?.children}
      <div className="warning-message">{props?.warningMessage}</div>
      <div className="error-message">{props?.errorMessage}</div>
      {props?.requiredVariant && <span className="required">*</span>}
    </div>
  )
}
